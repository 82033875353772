<template>
  <BRow
    class="justify-content-center align-items-center h-100"
    style="margin: 4rem 2rem; overflow: hidden"
  >
    <BCol md="8" lg="6" xl="5">
      <BCard no-body bg-variant="dark" shadow-lg>
        <BCardBody class="p-4 text-center">
          <div class="avatar-lg mx-auto">
            <span class="text-success">
              <BIconCheckCircleFill
                variant="success"
                font-scale="2"
              ></BIconCheckCircleFill>
            </span>
          </div>
          <div class="mt-4 pt-2">
            <h4>Well done!</h4>
            <p
              class="text-muted mx-4 my-2 text-center"
              style="direction: rtl"
              v-if="this.$store.state.phoneNumber.startsWith('96475')"
            >
              <!-- شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة من 3368. لإلغاء الاشتراك في أي وقت أرسل F7 إلى 3368. -->

              {{
                this.$i18n.locale === "ar"
                  ? "شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة. ارسل R1 إلى 3368  لإلغاء الاشتراك في أي وقت."
                  : "Thank you for subscribing! You will receive the content link via SMS. Send R1 to 3368 to unsubscribe at any time"
              }}
            </p>
            <p
              class="text-muted mx-4 my-2 text-center"
              style="direction: rtl"
              v-else
            >
              <!-- شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة من 3368. لإلغاء الاشتراك في أي وقت أرسل F7 إلى 3368. -->

              {{
                this.$i18n.locale === "ar"
                  ? "شكرا لاشتراكك! سوف تتلقى رابط المحتوى عبر رسالة نصية قصيرة. ارسل R1 إلى 3368  لإلغاء الاشتراك في أي وقت."
                  : "Thank you for subscribing! You will receive the content link via SMS. Send R1 to 3368 to unsubscribe at any time"
              }}
            </p>
          </div>
        </BCardBody>
      </BCard>
    </BCol>
  </BRow>
</template>
<style></style>
<script>
export default {
  name: "ThankYouPage",
}
</script>
